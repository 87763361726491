<template>
  <div style="height: 100%">
    <Breadcrumb :model="items"/>
    <div class="page p-mt-3 p-p-5">
      <div class="p-col-10">
        <div class="p-pt-2" style="font-size: 20px;font-weight: bold">{{from.name}}</div>
        <div class="p-d-flex p-pt-3">
          <div>地点: {{from.addr}}</div>
          <div class="p-ml-3">时间：{{new Date(from.time).toLocaleDateString()+' '+new Date(from.time).toLocaleTimeString()}}</div>
        </div>
        <div class="p-pt-5 " v-html="from.content"></div>
        <div class="p-pt-5">特邀行业专家：{{from.peopleList}}</div>
        <div class="p-mt-6 p-grid">
          <div class="p-col p-grid p-jc-center">
            <Button @click="refuse()" label="拒绝参会"/>
          </div>
          <div class="p-col p-grid p-jc-center">
            <Button @click="join()" label="确认参会"/>
          </div>
            <div class="p-col p-grid p-jc-center">
                <Button style="width: 86px" @click="goto()" label="返回"/>
            </div>
        </div>
      </div>
    </div>
  </div>
    <Toast/>
</template>

<script>
export default {
    mounted() {
        this.showEvent()
    },
    name: "EventDetail",
    data() {
        return {
          items: [
            {label: '活动组织管理', to: '/'},
            {label: '活动安排', to: '/events'},
            {label: '详情',}
          ],
            id: this.$route.query.id,
            from: {
                name: null,
                addr: null,
                time: null,
                content: null,
                peopleList: null,
            }
        }
    },
    methods: {
        goto(){
            this.$router.push('/events')
        },
        showEvent() {
            this.$http.get('/activity/seeDetails',{params:{'id':this.id}}).then((res) =>{
                console.log(res.data)
                this.from = res.data
            })
        },
        refuse() {
            let me = this
            this.$http.get('/activity/refuse' , {params:{'id': this.id, 'number': 0}}).then(() => {
                this.$toast.add({
                    severity: 'success', summary: '成功', detail: '已拒绝参加活动！', life: 3000
                })
                setTimeout( function (){
                    me.$router.push('/events')
                },1000)
            })
        },
        join() {
            let my = this
            this.$http.get('/activity/changePlan',{params: {'id': this.id, 'number':2}}).then(() =>{
                this.$toast.add({
                    severity: 'success', summary: '成功', detail: '已拒绝参加活动！', life: 3000
                })
                setTimeout( function (){
                    my.$router.push('/events')
                },1000)
            })
        },
    }

}
</script>

<style scoped>

</style>